import React from 'react'

const About = () => {
  return (
    <div className="page">
      <div className="container">
        <div className="row">
          <h3>We are a boutique, specialists in team building using a bottom up approach.</h3>
        </div>
      </div>
    </div>
  )
}

export default About
